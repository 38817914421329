export default {
	places: [
		{
			mainPlace: 'Монтана',
			subPlaces: [
				'гр. Монтана',
				'с. Георги Дамяново',
				'с. Благово',
				'с. Боровци',
				'с. Долно Белотинци',
				'с. Долна Вереница',
				'с. Ерден',
				'с. Николово',
				'гр. Брусарци',
				'с. Замфирово',
				'с. Котеновци',
				'с. Медковец',
				'гр. Лом',
				'гр. Берковица',
				'гр. Вършец',
				'гр. Вълчедръм',
				'гр. Бойчиновци',
				'с. Ягодово',
				'с. Бързия',
				'с. Спанчевци',
				'с. Комарево'
			]
		},
		{
			mainPlace: 'Враца',
			subPlaces: [
				'гр. Враца',
				'гр. Козлодуй',
				'гр. Оряхово',
				'с. Селановци',
				'с. Остров',
				'с. Войводово'
			]
		},
		{
			mainPlace: 'Благоевград',
			subPlaces: [
				'гр. Благоевград',
				'с. Церово',
				'гр. Кочериново',
				'с. Рилци',
				'с. Бучино',
				'с. Бараково',
				'с. Покровник',
				'с. Дъбрава',
				'с. Зелендол',
				'с. Изгрев',
				'с. Стоб',
				'с. Пороминово',
				'с. Бело Поле',
				'с. Селище',
				'с. Българчево',
				'с. Еленово',
				'с. Логодаж',
				'с. Делвино',
				'гр. Рила',
				'с. Смочево',
				'с. Мощанец'
			]
		}
	]
};
