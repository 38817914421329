import BottomCta from '../components/shared/bottom-cta';
import Collapsible from 'react-collapsible';
import GoogleMapComponent from '../components/shared/google-map';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import React from 'react';
import SEO from '../components/seo';
import SectionTitles from '../components/shared/section-titles';
import { TitleH1 } from './magazini';
import constants from '../constants/coverage';
import meta from '../constants/meta-descriptions';

const CoveragePage = () => {
	return (
		<>
			<Layout activeTab={'/pokritie/'}>
				<SEO
					title={meta.coverage.title}
					description={meta.coverage.metaDescription}
				/>

				<div style={{ paddingTop: '16rem' }}>
					<SectionTitles
						undertext={
							'Непрекъсваем оптичен интернет и интерактивна телевизия в Монтана, Враца и още куп градове и села на Северозапад, както и в Благоевград: NetSurf ти пази гърба – и в забавлението, и в труда.'
						}
					>
						<TitleH1>
							Покриваме те в 40
							<br />
							населени места
						</TitleH1>
						<hr className="center" />
					</SectionTitles>

					<div className="container">
						<div className="my-5">
							<GoogleMapComponent
								height={600}
								mapState={{
									center: {
										lat: 42.82360981,
										lng: 23.29376221
									},
									zoom: 8
								}}
								isRenderPolygon={true}
							/>
						</div>

						<AllCoveragePlaces />

						<BottomCta removeSpacing={true} bgColor={'transparent'}>
							<h2 className="mb-4 d-none d-md-block">
								Нямаме търпение да ти покажем
								<br />
								<span className="text-purple"> колко сме добри!</span>
							</h2>
							<h2 className="mb-4 d-block d-md-none">
								Нямаме търпение да ти покажем
								<span className="text-purple"> колко сме добри!</span>
							</h2>
							<p className="mb-5">
								Нашите пакети – по-благи от тези под коледната елха
							</p>
							<Link to={'/za-doma/'} className="btn btn-primary green">
								Виж пакетите
							</Link>
						</BottomCta>
					</div>
				</div>
			</Layout>
		</>
	);
};

export const AllCoveragePlaces = ({}) => (
	<>
		<div className="mb-5 text-center d-flex justify-content-center">
			<h2>Всички области</h2>
		</div>
		<div className="d-flex justify-content-center">
			<div className="col-12 col-lg-9 col-xl-8">
				{constants.places.map((place, index) => (
					<CElement {...place} isInitiallyOpen={index === 0} />
				))}
			</div>
		</div>
	</>
);

const CElement = ({ mainPlace, subPlaces, isInitiallyOpen }) => {
	return (
		<>
			<Collapsible
				open={isInitiallyOpen}
				trigger={<CHeader isOpen={false} places={subPlaces} title={mainPlace} />}
				triggerWhenOpen={
					<CHeader isOpen={true} places={subPlaces} title={mainPlace} />
				}
			>
				<CContent places={subPlaces} />
			</Collapsible>
		</>
	);
};

const CContent = ({ places }) => {
	return (
		<>
			<div className="row">
				{places.map((place, index) => (
					<div className="col-6 col-md-4" key={place + index}>
						<p>
							<span className="mr-2 icon map-pin"></span>
							{place}
						</p>
					</div>
				))}
			</div>
		</>
	);
};

const CHeader = ({ isOpen, title, places }) => {
	return (
		<>
			<div className="mt-2 row w-100">
				<button className="clear w-100">
					<div className="mb-0 d-flex justify-content-between">
						<h3 className={'text-left'}>
							<span className="mr-2 icon green-tick"></span>
							{title}
						</h3>
						<p className={'text-left'}>
							<span className="d-none d-sm-inline">
								+{places.length} населени места
							</span>
							<span
								className={
									'icon ml-2 ' +
									(!isOpen ? 'arrow-close' : 'arrow-open')
								}
							></span>
						</p>
					</div>
					<hr className="p-0 m-0 normal-bottom" />
				</button>
			</div>
		</>
	);
};

export default CoveragePage;
